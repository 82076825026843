import React, { Component } from 'react';

class DownloadApk extends Component {
    render() {
        return (
            <>
                <h1>Download Apk</h1>
            </>
        )
    }
}

export default DownloadApk
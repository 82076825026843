import React, { Component } from 'react';

class CommingSoon extends Component {
    render() {
        return (
            <>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
                    <h1 style={{fontWeight: '800'}}>Comming Soon</h1>
                </div>
            </>
        )
    }
}

export default CommingSoon